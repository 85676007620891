import '../App.css'
import Cities from './filters/Cities';
import CitiesNew from "./filters/CitiesNew";
import DepartmentsNew from "./filters/DepartmentsNew";
import UniversitiesNew from "./filters/UniversitiesNew";
import Departments from './filters/Departments';
import PointRange from './filters/PointRange';
import RankRange from './filters/RankRange';
import PointTypes from "./filters/PointTypes";
import SearchButton from "./filters/SearchButton";
function Filters({ filters, setFilters, filterTrigger, setFilterTrigger }) {
  return (
    <div>
      <CitiesNew filters={filters} setFilters={setFilters} />

      <UniversitiesNew filters={filters} setFilters={setFilters} />

      <DepartmentsNew filters={filters} setFilters={setFilters} />

      <PointTypes filters={filters} setFilters={setFilters} />

      <RankRange filters={filters} setFilters={setFilters} />

      <PointRange filters={filters} setFilters={setFilters} />

      <SearchButton
        setFilterTrigger={setFilterTrigger}
        filterTrigger={filterTrigger}
      />
    </div>
  );
}

export default Filters;
