import React from 'react'

function SearchButton({ filterTrigger, setFilterTrigger }) {
    const handeClick = ()=>{
        setFilterTrigger(filterTrigger +1)
        //console.log(filterTrigger);
    }
  return (
    <div>
      <ol className="list-group" style={{ margin: "1em" }}>
        <li className="list-group-item">
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => handeClick()}
          >
            Ara
          </button>
        </li>
      </ol>
    </div>
  );
}

export default SearchButton