import React from "react";
import { useEffect, useState } from "react";

function AccordionMenu({
  favDepartments,
  setFavDepartments,
  favoriteDepartmentTrigger,
  setFavoriteDepartmentTrigger,
}) {
  // Favori üniversiteleri localStorage'da saklamak için anahtar
  const localStorageKey = "favoriteUniversities";

  // Bilgiler Bölümü 
  const [openRows, setOpenRows] = useState({});

  const toggleRow = (index) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  // Favori üniversiteyi silmek için fonksiyon
  const deleteFavoriteDepartment = (university) => {
    var storedFavorites = localStorage.getItem(localStorageKey);
    if (storedFavorites) {
      const favorites = JSON.parse(storedFavorites);
      const newFavorites = favorites.filter(
        (item) => item.PROGRAM_KODU !== university.PROGRAM_KODU
      );
      localStorage.setItem(localStorageKey, JSON.stringify(newFavorites));
    }
    // Trigger state'i güncelleyerek useEffect'i tetikle
    setFavoriteDepartmentTrigger(favoriteDepartmentTrigger + 1);
  };

  useEffect(() => {
    const storedFavorites = localStorage.getItem(localStorageKey);
    if (storedFavorites) {
      setFavDepartments(JSON.parse(storedFavorites));
    }
  }, [favoriteDepartmentTrigger]);

  return (
    <div className="position-fixed favorite-departments">
      <div className="accordion " id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button text-center"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Favori Bölümlerim
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <table className="table table-dark table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Bölüm</th>
                    <th scope="col">Üniversite</th>
                    <th scope="col">Puan</th>
                    <th scope="col">Sıralama</th>
                    <th scope="col">Bilgiler</th>
                    <th scope="col"></th>

                  </tr>
                </thead>
                <tbody>
                  {favDepartments.map((university, index) => (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {university.BOLUM_PROGRAM}
                          {university.EK_BILGI
                            ? ` (${university.EK_BILGI})`
                            : ""}{" "}
                          <span className="badge bg-secondary">
                            {university.PUAN_TIP}
                          </span>
                        </td>
                        <td>{university.UNIVERSITE}</td>
                        <td>{university.TABAN_PUANI_23}</td>
                        <td>{university.BASARI_SIRASI_23}</td>
                        <td
                          scope="row"
                          onClick={() => toggleRow(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <button
                            type="button"
                            className="btn btn-outline-warning btn-sm"
                          >
                            ⓘ
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => deleteFavoriteDepartment(university)}
                            className="btn btn-danger btn-sm"
                          >
                            -
                          </button>
                        </td>
                      </tr>
                      {openRows[index] && (
                  <tr>
                    <td colSpan="12">
                      <table className="main-table table table-dark ">
                        <tbody>
                          <tr>
                            <td><p>Şehir : {university.SEHIR}</p></td>
                            <td><p>Program Kodu : {university.PROGRAM_KODU}</p></td>
                            <td><p>Fakülte : {university.FAKULTE}</p></td>
                          </tr>
                          <tr>
                            <td><p>KONTENJAN : {university.KONTENJAN}</p></td>
                            <td><p>2022 Sıralama : {university.BASARI_SIRASI_22}</p></td>
                            <td><p>2021 Sıralama : {university.BASARI_SIRASI_21}</p></td>
                          </tr>
                          <tr>
                            <td><p>Süre : {university.SURE} yıl</p></td>
                            <td><p>2022 Puanı : {university.TABAN_PUANI_22}</p></td>
                            <td><p>2021 Puanı : {university.TABAN_PUANI_21}</p></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
                    </React.Fragment>

                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccordionMenu;
