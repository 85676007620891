import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify"

function UniTable({
  uniData,
  setFavDepartments,
  favoriteDepartmentTrigger,
  setFavoriteDepartmentTrigger,
  searchResults,
  setResultNumber,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const localStorageKey = "favoriteUniversities";
  const itemsPerPage = 30;

  const addFavoriteUniversity = (university) => {
    const storedFavorites = localStorage.getItem(localStorageKey);
    const favorites = storedFavorites ? JSON.parse(storedFavorites) : [];

    const isDuplicate = favorites.some(
      (item) => item.PROGRAM_KODU === university.PROGRAM_KODU
    );

    if (!isDuplicate) {
      console.log(university);
      favorites.push(university);
      localStorage.setItem(localStorageKey, JSON.stringify(favorites));
      setFavoriteDepartmentTrigger(favoriteDepartmentTrigger + 1);
      toast('✅ Bölüm Favorilere Eklendi ')
    }
  };

  useEffect(() => {
    const storedFavorites = localStorage.getItem(localStorageKey);
    if (storedFavorites) {
      setFavDepartments(JSON.parse(storedFavorites));
    }
  }, [favoriteDepartmentTrigger]);

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  var currentItems = NaN;
  var pageLenght = 0;

  if (searchResults && Array.isArray(searchResults)) {
    currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);
    setResultNumber(searchResults.length);
    pageLenght = Math.ceil(searchResults.length / itemsPerPage);
  } else {
    currentItems = [];
    pageLenght = 1;
  }

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const [openRows, setOpenRows] = useState({});

  const toggleRow = (index) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <>
      <div className="main-table-container">
        <table className="main-table table table-dark table-striped margin-6">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Bölüm</th>
              <th scope="col">Universite</th>
              <th scope="col">Puan</th>
              <th scope="col">Sıralama</th>
              <th scope="col">Bilgiler</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((university, index) => (
              <React.Fragment key={index}>
                <tr>
                  <th scope="row" onClick={() => toggleRow(index)}>
                    {indexOfFirstItem + index + 1}
                  </th>
                  <td>
                    {university.BOLUM_PROGRAM}
                    {university.EK_BILGI
                      ? ` (${university.EK_BILGI})`
                      : ""}{" "}
                    <span className="badge bg-warning dark-orange">
                      {university.PUAN_TIP}
                    </span>
                  </td>
                  <td>{university.UNIVERSITE} </td>
                  <td>{university.TABAN_PUANI_23}</td>
                  <td>{university.BASARI_SIRASI_23}</td>
                  <td
                    scope="row"
                    onClick={() => toggleRow(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-warning btn-sm"
                    >
                      ⓘ
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => addFavoriteUniversity(university)}
                      className="btn btn-success btn-sm"
                    >
                      +
                    </button>
                  </td>
                </tr>
                {openRows[index] && (
                  <tr>
                    <td colSpan="12">
                      <table className="main-table table table-dark ">
                        <tbody>
                          <tr>
                            <td><p>Şehir : {university.SEHIR}</p></td>
                            <td><p>Program Kodu : {university.PROGRAM_KODU}</p></td>
                            <td><p>Fakülte : {university.FAKULTE}</p></td>
                          </tr>
                          <tr>
                            <td><p>KONTENJAN : {university.KONTENJAN}</p></td>
                            <td><p>2022 Sıralama : {university.BASARI_SIRASI_22}</p></td>
                            <td><p>2021 Sıralama : {university.BASARI_SIRASI_21}</p></td>
                          </tr>
                          <tr>
                            <td><p>Süre : {university.SURE} yıl</p></td>
                            <td><p>2022 Puanı : {university.TABAN_PUANI_22}</p></td>
                            <td><p>2021 Puanı : {university.TABAN_PUANI_21}</p></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="margin-bottom">
        <ReactPaginate
          previousLabel={"Önceki"}
          nextLabel={"Sonraki"}
          breakLabel={"..."}
          pageCount={pageLenght}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"dark-orange"}
          activeLinkClassName={"dark-orange"}
        />
      </div>
    </>
  );
}

export default UniTable;
