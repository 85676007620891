import './App.css';
import Navbar from './components/Navbar'
import UniCounts from './components/UniCounts'
import Filters from './components/Filters'
import UniTable from './components/UniTable'
import AccordionMenu from './components/AccordionMenu';

import React, { useEffect, useState } from "react";
import SearchForm from './components/SearchForm';

function App() {
  const [uniData, setUniData] = useState([]);
  const [searchResults, setSearchResults] = useState(null);
  const [searchKey, setSearchKey] = useState("hacettepe");
  const [favDepartments, setFavDepartments] = useState([]);
  const [favoriteDepartmentTrigger, setFavoriteDepartmentTrigger] = useState(0);
  const [filterTrigger, setFilterTrigger] = useState(0);
  const [resultNumber, setResultNumber] = useState();
  const [filters, setFilters] = useState({
    cities: ["ANKARA"],
    departments: [],
    universities: ["HACETTEPE"],
    pointTypes: [],
    minRankRange: 0,
    maxRankRange: 99999999,
    minPointRange: 0,
    maxPointRange: 999,
  });

  //useEffect(() => {
  //  console.log(filters);
  //}, [filters]);

  //<GetData
  //  setUniData={setUniData}
  //  searchKey={searchKey}
  //  setSearchKey={setSearchKey}
  //  setResultNumber={setResultNumber}
  //  filters={filters}
  ///>;

  return (
    <div className="App">
      <Navbar />
      <div className="container container-sm text-center">
        <div className="row">
          <div className="col-12 col-md-3  justify-content-center">
            <UniCounts resultNumber={resultNumber} />
            <Filters
              filters={filters}
              setFilters={setFilters}
              filterTrigger={filterTrigger}
              setFilterTrigger={setFilterTrigger}
            />
          </div>
          <div className="col-12 col-md">
            {/*<SearchBar searchKey={searchKey} setSearchKey={setSearchKey} />*/}
            <SearchForm
              filters={filters}
              setFilters={setFilters}
              filterTrigger={filterTrigger}
              setSearchResults={setSearchResults}
            ></SearchForm>
            <UniTable
              uniData={uniData}
              searchResults={searchResults}
              favDepartments={favDepartments}
              setFavDepartments={setFavDepartments}
              favoriteDepartmentTrigger={favoriteDepartmentTrigger}
              setFavoriteDepartmentTrigger={setFavoriteDepartmentTrigger}
              setResultNumber={setResultNumber}
            />
            <AccordionMenu
              favDepartments={favDepartments}
              setFavDepartments={setFavDepartments}
              favoriteDepartmentTrigger={favoriteDepartmentTrigger}
              setFavoriteDepartmentTrigger={setFavoriteDepartmentTrigger}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
