import React, { useEffect,useState } from "react";

function SearchForm({ filters, setSearchResults, filterTrigger }) {
  const [isLoading, setIsLoading] = useState(false);
  const handleSearch = async () => {
    //console.log("Search MADE !!! : ", filters);
    try {
      setIsLoading(true); // Sorgu başladığında loading durumunu true yap

      const response = await fetch(
        "https://uni-point-backend-api.vercel.app/melih",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filters),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      //console.log(data);
      setSearchResults(data);
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setIsLoading(false); // Sorgu sona erdiğinde loading durumunu false yap
    }
    
  };

  useEffect(() => {
    handleSearch();
  }, [filterTrigger]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
    </>
  );
}

export default SearchForm;
