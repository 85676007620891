
function UniCounts({ resultNumber }) {
  return (
    <div
      style={{
        color: "black",
        margin: "1.5em",
        padding: "0.5em",
        background: "rgba(255,255,255,0.5)",
        borderRadius: "10px",
      }}
    >
      <h3>
        Sonuç : <span className="badge bg-success">{resultNumber}</span>
      </h3>
    </div>
  );
}

export default UniCounts