import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import React, { useState, useEffect } from "react";

const animatedComponents = makeAnimated();


const customStyles = {
  input: (styles) => ({
    ...styles,
    color: "rgb(232,230,227)", // Dark text color for input
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgb(36,39,41)", // Darker background for the control
    borderColor: state.isFocused ? "rgb(191,101,0)" : "rgb(28,30,31)", // Dark border color
    color: "rgb(232,230,227)", // Light text color
    "&:hover": {
      borderColor: "rgb(191,101,0)", // Highlight border color on hover
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "rgb(232,230,227)" : "rgb(232,230,227)", // Light text color
    backgroundColor: state.isSelected ? "rgb(191,101,0)" : "rgb(28,30,31)", // Dark background color
    ":hover": {
      backgroundColor: "rgb(36,39,41)", // Slightly lighter background on hover
      color: "rgb(232,230,227)",
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "rgb(239, 126, 0)", // Dark background for selected items
    color: "rgb(232,230,227)", // Light text color for selected items
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "rgb(232,230,227)",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "rgb(232,230,227)", // Light color of the remove icon
    ":hover": {
      backgroundColor: "rgb(191,101,0)", // Highlight background color of remove icon on hover
      color: "rgb(232,230,227)",
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "rgb(28,30,31)", // Dark background for the dropdown menu
    color: "rgb(232,230,227)", // Light text color for the dropdown menu
  }),
  // ... Add more customizations as needed
};

export default function Cities({ filters, setFilters }) {
  const [citiyList, setCityList] = useState([]);
  const [selectedCitiyList, setSelectedCitiyList] = useState([]);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await axios.get(
          "https://uni-point-backend-api.vercel.app/city"
        );
        //response.data.sort((a, b) => b.p2022 - a.p2022);
        setCityList(response.data);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      }
    };
    fetchCity();
  }, []);


  // seçilen şehirleri filtrelere ekle
  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      cities: selectedCitiyList.map(city=>city.value),
    }));
  }, [selectedCitiyList]);

  const options = citiyList.map((item) => ({
    value: item[0],
    label: item[0],
  }));

  return (
    <ol className="list-group" style={{ margin: "1em" }}>
      <li className="list-group-item">
        <h5>Şehirler</h5>
      </li>

      <Select
        className="multi-select"
        style={{ margin: "1em" }}
        closeMenuOnSelect={false}
        components={animatedComponents}
        //defaultValue={[colourOptions[4], colourOptions[5]]}
        isMulti
        options={options}
        styles={customStyles}
        onChange={(selectedOptions) => {
          setSelectedCitiyList(selectedOptions || []);
        }}
      />
    </ol>
  );
}
