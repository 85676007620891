import React from 'react'

function PointRange({ setFilters }) {

   const handleInputChange = (inputNumber, event) => {
     const inputValue = event.target.value;

     if (inputNumber === "min") {
       setFilters((prevFilters) => ({
         ...prevFilters,
         minPointRange: inputValue,
       }));
     } else {
       setFilters((prevFilters) => ({
         ...prevFilters,
         maxPointRange: inputValue,
       }));
     }
   };



  return (
    <ol className="list-group" style={{ margin: "1em" }}>
      <li className="list-group-item">
        <h5>Puan Aralığı</h5>
      </li>

      <li className="list-group-item">
        <input
          type="number"
          id="btncheck1"
          placeholder="Min Puan"
          style={{ textAlign: "center", width: "80%" }}
          onChange={(event) => handleInputChange("min", event)}
        />
      </li>
      <li className="list-group-item">
        <input
          type="number"
          id="btncheck2"
          placeholder="Maks Puan"
          style={{ textAlign: "center", width: "80%" }}
          onChange={(event) => handleInputChange("max", event)}
        />
      </li>
    </ol>
  );
}

export default PointRange