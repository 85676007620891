import { useEffect } from "react";

function PointTypes({ filters, setFilters }) {


  const handleCheckboxChange = (pointType) => {
    // İlgili puan türü zaten seçili mi?
    const isPointTypeSelected = filters.pointTypes.includes(pointType);

    // Seçili ise, filtreleri güncelle ve bu puan türünü kaldır.
    // Seçili değilse, filtreleri güncelle ve bu puan türünü ekle.
    if (isPointTypeSelected) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        pointTypes: prevFilters.pointTypes.filter((type) => type !== pointType),
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        pointTypes: [...prevFilters.pointTypes, pointType],
      }));
    }
  };



  return (
    
    <ol className="list-group" style={{ margin: "1em" }}>
      <li className="list-group-item">
        <h5>Puan Türleri</h5>
      </li>

      <li className="list-group-item">
        <div
          className="btn-group"
          role="group"
          aria-label="Basic checkbox toggle button group"
        >
          <input
            type="checkbox"
            className="btn-check"
            id="btncheck1"
            onChange={() => handleCheckboxChange("SAY")}
            checked={filters.pointTypes.includes("SAY")}
          />
          <label className="btn btn-outline-warning" htmlFor="btncheck1">
            SAY
          </label>

          <input
            type="checkbox"
            className="btn-check"
            id="btncheck2"
            onChange={() => handleCheckboxChange("SÖZ")}
            checked={filters.pointTypes.includes("SÖZ")}
          />
          <label className="btn btn-outline-warning" htmlFor="btncheck2">
            SÖZ
          </label>

          <input
            type="checkbox"
            className="btn-check"
            id="btncheck3"
            onChange={() => handleCheckboxChange("EA")}
            checked={filters.pointTypes.includes("EA")}
          />
          <label className="btn btn-outline-warning" htmlFor="btncheck3">
            EA
          </label>

          <input
            type="checkbox"
            className="btn-check"
            id="btncheck4"
            onChange={() => handleCheckboxChange("DİL")}
            checked={filters.pointTypes.includes("DİL")}
          />
          <label className="btn btn-outline-warning" htmlFor="btncheck4">
            DİL
          </label>
        </div>
      </li>
    </ol>
  );
}

export default PointTypes;