import 'bootstrap/dist/css/bootstrap.min.css';

function navbar() {
  return (
    <>
      <nav className="navbar  navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <img src="https://i.ibb.co/r2J3CkN/unipuan-logo.png" width="30rm" />
          <a className="navbar-brand" href="#">
            UniPuan
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  target="_blank"
                  href="https://www.sunman.dev/"
                >
                  İletişim
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default navbar
