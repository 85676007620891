function RankRange({ setFilters }) {


  const handleInputChange = (inputNumber, event) => {

      const inputValue = event.target.value;
      
      if (inputNumber==='min'){
        setFilters((prevFilters) => ({
          ...prevFilters,
          minRankRange: inputValue,
        }));
      }
      else{
        setFilters((prevFilters) => ({
          ...prevFilters,
          maxRankRange: inputValue,
        }));
      }
    };

  
  return (
    <ol className="list-group" style={{ margin: "1em" }}>
      <li className="list-group-item">
        <h5>Sıralama Aralığı</h5>
      </li>

      <li className="list-group-item">
        <input
          type="number"
          id="btncheck1"
          placeholder="Min Sıralama"
          style={{ textAlign: "center", width: "80%" }}
          onChange={(event) => handleInputChange("min", event)}
        />
      
        
      </li>
      <li className="list-group-item">
      <input
          type="number"
          id="btncheck2"
          placeholder="Maks Sıralama"
          style={{ textAlign: "center", width: "80%" }}
          onChange={(event) => handleInputChange("max", event)}
        />
      </li>
    </ol>
  );
}

export default RankRange;
